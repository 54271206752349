.Accepted {
    margin-top: 10px;
    float: left;
    color: yellowgreen;
}

.caseButton {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
}

.Reject {
    float: left;
    color: red;
}

.formUserButton {
    background-color: #32384e;
    color: #c99e11;
    padding: 14px 10px;
    margin-top: 20px;
    cursor: pointer;
    /*width: 100%;*/
}

.formUserButton:hover {
    background-color: rgba(54, 61, 86, 0.4);
    color: #c99e11;
    padding: 14px 10px;
    margin-top: 20px;
    cursor: pointer;
    /*width: 100%;*/
}

.Title {
    color: #6bb5ff;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.State {
    width: 100%;
    margin-top: 50px;
    margin-right: 10px;
    color: #5a5a5a;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.close {
    cursor: pointer;

}
.close:hover {
    color: #717171;
    cursor: pointer;

}
